<template>
<div id="AddUser">

<!-- notification -->
<v-snackbar v-model="notificationToggle" :color="notificationType" :timeout="5000" :top="true"> {{ notificationMsg }}
  <v-btn dark text @click="notificationToggle = false" > Close </v-btn>
</v-snackbar>

<!-- add form -->
<v-form @submit.prevent="AddUser">
<v-container class="pa-5">
<v-card class="pa-2 mt-5 elevation-5">
  <v-card-title>
    <h3>Add User</h3>
  </v-card-title>
  <v-card-text class="pa-2">
    <v-row>
      <!-- add form -->
      <!-- file upload -->
      <v-col cols="12" sm="12" md="6">
        <v-file-input v-model="files" color="deep-purple accent-4" single-line label="Upload Image"
        prepend-icon="mdi-google-photos" accept="image/*">
        </v-file-input>
      </v-col>
      <!-- other fields -->
      <v-col cols="12" sm="12" md="6">
          <v-text-field label="Name" :error="$v.Name.$error" :error-messages="NameErrors"
          v-model.trim="Name" @input="$v.Name.$touch()" required></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="6">
          <v-text-field type="email" label="Email" :error="$v.Email.$error" :error-messages="EmailErrors"
          v-model.trim="Email" @input="$v.Email.$touch()" required></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="6">
          <v-text-field type="number" label="Phone" :error="$v.Phone.$error" :error-messages="PhoneErrors"
          v-model.trim="Phone" @input="$v.Phone.$touch()" required></v-text-field>
      </v-col>
       <v-col cols="12" sm="12" md="12">
        <v-checkbox v-model="AdminFlag" label="Make As Admin, Default is Teacher" ></v-checkbox>
      </v-col>
    </v-row>
  </v-card-text>
  <v-card-actions class="pa-3">
      <v-btn type="submit" class="success elevation-10" :disabled="disbtn">Add User</v-btn>
      <v-btn @click="$router.go(-1)" class="secondary elevation-10">back</v-btn>
  </v-card-actions>

  <!-- show overlay when add item -->
  <v-fade-transition>
  <v-overlay absolute :value="disbtn">
    <v-progress-circular color="yellow" indeterminate width="15" size="100"></v-progress-circular>
  </v-overlay>
  </v-fade-transition>

</v-card>
</v-container>
</v-form>

</div>
</template>

<script>
// imports
import { required, email } from 'vuelidate/lib/validators';
import firebase from 'firebase/app';
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

// scripts
export default {

    name: 'AddUser',

     data(){return{
        
        // app flags
        notificationToggle: false,notificationMsg: 'hai',notificationType: 'hui',disbtn: false,

        // user flags
        Name: '',Email: '',Phone: null,AdminFlag: false,files: [],
    }},

    methods:{

      // add user
      async AddUser(){
        
        // blank validation
        if(!this.BlankValidation()){return false;}    
        this.disbtn = true;    
        // signup code
        let createUser = firebase.functions().httpsCallable('createUser');
        createUser({
          email: this.Email,
          name: this.Name,
        }).then((user) => {

            // get userid
            let userid = user.data.uid
            // add user data
            firebase.firestore().collection("users").add({
                "uid": userid,"email": this.Email,"displayName": this.Name,"phone": Number(this.Phone)
            }).then((doc) => {

                // make user admin or teacher
                if(!this.AdminFlag){
                  // make teacher
                  let TeacherCall =  firebase.functions().httpsCallable('addTeacherRole');
                  TeacherCall({email: this.Email}).then((per) => {
                      // image upload
                      this.ImageUpload(doc.id);
                  }).catch((err) => {this.notificationService("Server Error, Try After Some Time Or Call Developer", "red darken-1");});
                }else{
                  // make admin 
                  let AdminCall =  firebase.functions().httpsCallable('addAdminRole');
                  AdminCall({email: this.Email}).then((per) => {
                      // image upload
                      this.ImageUpload(doc.id);
                  }).catch((err) => {this.notificationService("Server Error, Try After Some Time Or Call Developer", "red darken-1");});
                }

            }).catch((err) => {
                // spit out error
                this.disbtn = false;
                this.notificationService("Server Error, Try After Some Time Or Call Developer", "red darken-1");
            });     

         
        }).catch((err) => {
            // spit out error
            this.disbtn = false;
            this.notificationService("Email Already Exists!", "red darken-1");
        });     

      },

      // image upload function
      ImageUpload(key){

        // vars
        let imageFile;let imageFileName;let ext;let imageUrl;;let task;
        // check if image exists, run Final
        if(this.files == null) { return this.Final(); }
        // actual add file
        imageFile = this.files;
        // get filename
        imageFileName = imageFile.name;
        // get extention of filename
        ext = imageFileName.slice(imageFileName.lastIndexOf('.'));
        // get a reference to store image in firebase storage
        let storageRef = firebase.storage().ref('users/' + key + ext);
        // upload image
        storageRef.put(imageFile).then((snapshot) => {
          // get download url and update db
          snapshot.ref.getDownloadURL().then((downloadURL) => {
            firebase.firestore().collection('users').doc(key).update({ photoURL: downloadURL})
            .then((res) => { this.Final(); }).catch((err) => {console.log(err)});
          });
        }).catch((err) => {
           // spit out error
          this.disbtn = false;   
          this.notificationService("Server Error, Image Upload Failed, Try After Some Time", "red darken-1");
        });
   
      },

      // run after all
      Final(){
          this.ResetFields(); 
          this.notificationService("User Added SuccessFully!", "success");
          this.disbtn = false;   
      },

      // reset fields
      ResetFields(){     
        this.files = null;this.Name = '';this.Email = '';this.Phone = null,this.AdminFlag = false;
        this.$v.$reset();
      },

      // check if all fileds are empty   
      BlankValidation(){
        if(this.files){
         if(this.files.size / 1024 >= 2048){this.notificationService("File Should be Under 2MB!", "red darken-1");return false;}
        }
        if(this.files == null || this.files.length == 0 || this.Name == '' || this.Email == '' || this.Phone == null)
        {this.notificationService("Fill All Fields!", "red darken-1"); return false;}else{return true;}
      },
      
      // error pusher for vuelidate   
      ErrorPusher(basic, extra, msg){
        const errors = []
        if (!basic) return errors
        !extra && errors.push(msg);
        return errors;
      },

      // notification service
      notificationService(msg, type){
        this.notificationType = type;
        this.notificationMsg = msg;
        this.notificationToggle = true;
      },
      
    },

    // validations
    validations: {
        Name: { required }, 
        Email: { required, email },
        Phone: { required },
    },

    computed: {
  
      // basically in computed, i added validations  
      // sorry, i cant find a way to make this even smaller : -D
      NameErrors () { return this.ErrorPusher(this.$v.Name.$dirty, this.$v.Name.required, 'Name is Required'); },
      EmailErrors () { return this.ErrorPusher(this.$v.Email.$dirty, this.$v.Email.email, 'A Valid Email is Required'); },
      PhoneErrors () { return this.ErrorPusher(this.$v.Phone.$dirty, this.$v.Phone.required, 'Phone is Required'); },
      
    }

}
</script>

<style lang="scss" scoped>

</style>